import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  getAccessibilityContactRequest,
  IAccessibilityContactRequest,
} from '@services/contactservice'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'

import AccessibilityContactCard from '@objects/cards/accessibilitycontact'

export default function AccessibilityContactRequestPage() {
  const intl = useIntl()
  const [accessibilityContactRequests, setAccessibilityContactRequests] =
    useState<Array<IAccessibilityContactRequest>>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    updateContactRequestList()
  }, [])

  function onActionClick() {
    updateContactRequestList()
  }

  function updateContactRequestList() {
    setLoading(true)
    getAccessibilityContactRequest().then((request) => {
      setAccessibilityContactRequests(request)
      setLoading(false)
    })
  }

  function renderCards() {
    if (!accessibilityContactRequests?.length) return

    return accessibilityContactRequests.map((request) => (
      <AccessibilityContactCard
        key={request.id}
        request={request}
        onActionClick={onActionClick}
      />
    ))
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'page.gdpr.note',
        })}
      />
      <LoadingSkeleton loading={loading} type="card" number={4}>
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}
