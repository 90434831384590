import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { parseISO, format } from 'date-fns'
import { de } from 'date-fns/locale'

import {
  deleteAccessibilityContactRequests,
  getAccessibilityContactRequestImage,
  IAccessibilityContactRequest,
} from '@services/contactservice'

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Toolbar,
  Box,
} from '@mui/material'
import useDialog from '@hooks/useDialog'
import { styled } from '@mui/system'

const Info = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '1.4',
  color: theme.palette.primary.contrastText,
}))

const Img = styled('img')({})

export type ContactCardProps = AT.IReactPropsDefaults & {
  onActionClick?: () => void
  request: IAccessibilityContactRequest
}

export default function ContactCard({
  onActionClick,
  request,
}: ContactCardProps) {
  const [image, setImage] = useState<string>()
  const { toggle } = useDialog()

  function formatDate(date: string, formatStr: string) {
    return format(parseISO(date), formatStr, {
      locale: de,
    })
  }

  useEffect(() => {
    if (request.hasFile) {
      getAccessibilityContactRequestImage(request.id).then(async (res) => {
        setImage(
          `data:${res.headers['content-type']};base64,${btoa(
            String.fromCharCode(...new Uint8Array(res.data))
          )}`
        )
      })
    }
  }, [request.hasFile])

  return (
    <Card
      elevation={3}
      sx={{
        my: 12,
        mx: 0,
        width: '100%',
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
          p: 4,
        }}
      >
        <Grid
          container
          direction="row"
          spacing={1}
          wrap="nowrap"
          justifyContent="space-between"
          alignContent="flex-end"
        >
          <Grid item container direction="column" xs={8} spacing={0}>
            <Grid item>
              <Info>{request.url}</Info>
            </Grid>
            <Grid item>
              <Info>{request.email}</Info>
            </Grid>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end">
            <Grid item>
              <Info>
                Gemeldet am{' '}
                {formatDate(request.timestamp, "dd.MM.yyyy 'um' HH:mm 'Uhr'")}
              </Info>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <CardContent>
        <Grid
          sx={{
            mt: 2,
          }}
          container
          wrap="nowrap"
          spacing={2}
          direction="column"
        >
          {!!request.description && (
            <Typography
              sx={{
                my: 4,
                mx: 2,
              }}
              dangerouslySetInnerHTML={{ __html: request.description }}
            />
          )}
          {image && (
            <Box mt={5}>
              <Typography>
                Auf Bild klicken, um es im neuen Tab zu öffnen:
              </Typography>
              <a
                href={`${process.env.GATSBY_MICROSERVICE_API}api/contact/admin/requests/accessibility/image/${request.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Img sx={{ maxWidth: '300px' }} src={image} alt="" />
              </a>
            </Box>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                toggle('areyousure', () =>
                  deleteAccessibilityContactRequests(request.id).then(() => {
                    if (onActionClick) onActionClick()
                  })
                )
              }}
            >
              <FormattedMessage id="button.delete" />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
